







import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Product Imports Management",
          urlEnabled: true,
          search: {},
          filters: {
            filters: {
              productVariantId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Product Variant",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "productVariants",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              search,
                              limit,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "in",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              time: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Time (From)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Time (To)",
                    },
                  },
                },
              },
            },
          },
          displayFields: {
            time: {
              text: "Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            productVariant: {
              text: "Product Variant",
              options: {
                label: true,
                subProp: "productVariant.name",
                sortBy: "productVariant.value",
              },
            },
            quantity: {
              text: "Quantity",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            description: {
              text: "Description",
              sortable: true,
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            export: {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Export Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map(
                              (item) => item._id
                            ),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download(
                    "productImports",
                    `@/export`,
                    findAllOptions
                  );
                },
              },
            },
            import: {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Import Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Import Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled =
                        new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Import Excel",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel =
                          dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Upload",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "productImports",
                                  "import",
                                  {},
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: {},
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call(
                "productImports",
                "findAll",
                options
              );
              return [items, count];
            },
            async insert(item) {
              item.supplier = 'OnePrint'
              item.unit = 'pcs'
              return await coreApiClient.call(
                "productImports",
                "create",
                undefined,
                item
              );
            },
            async update(item) {
              return await coreApiClient.call(
                "productImports",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("productImports", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              time: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Time",
                  required: true,
                },
              },
              productVariantId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Product Variant",
                  "item-value": "_id",
                  "item-text": "name",
                  required: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "productVariants",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
              },
              quantity: {
                type: "number",
                attrs: {
                  label: "Quantity",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Description",
                },
              },
            },
            insertForm: {
              content: {
                fieldNames: [
                  "time",
                  "productVariantId",
                  "quantity",
                  "description",
                ],
              },
            },
            editForm: {
              content: {
                fieldNames: [
                  "time",
                  "productVariantId",
                  "quantity",
                  "description",
                ],
              },
            },
            delete: {
              confirmDisplayField: "description",
            },
          },
        },
      },
    };
  }
});
